import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  User, Moon, Sun, Lock, Edit2, LogOut, 
  Mail, Phone, Loader2, Upload, Download
} from 'lucide-react';

const Settings = () => {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address: ''
  });

  const getAuthHeaders = () => {
    const token = localStorage.getItem('access_token');
    return {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    };
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('user');
    if (storedUserData) {
      try {
        const parsedData = JSON.parse(storedUserData);
        setUserData(parsedData);
        setFormData({
          first_name: parsedData.first_name,
          last_name: parsedData.last_name,
          email: parsedData.email,
          phone_number: parsedData.phone_number || '',
          address: parsedData.address || ''
        });
      } catch (error) {
        console.error('Error parsing user data:', error);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }

    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, [navigate]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
    localStorage.setItem('darkMode', !darkMode);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setError('');
    
    try {
      const formData = new FormData();
      formData.append('profile_photo', file);

      const response = await fetch(`https://asobarespwa-d5839e56ffae.herokuapp.com/api/users/profile/${userData.id}/upload-photo/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${localStorage.getItem('access_token')}`,
          // No incluimos Content-Type aquí porque FormData lo establece automáticamente con el boundary correcto
        },
        credentials: 'include',
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error al subir la foto');
      }

      const updatedUser = await response.json();
      setUserData(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setSuccess('Foto de perfil actualizada correctamente');
    } catch (err) {
      setError(err.message || 'Error al subir la foto de perfil');
      console.error('Error details:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`https://asobarespwa-d5839e56ffae.herokuapp.com/api/users/profile/${userData.id}/update/`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        credentials: 'include',
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error al actualizar el perfil');
      }

      const updatedUser = await response.json();
      setUserData(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      setIsEditing(false);
      setSuccess('Perfil actualizado correctamente');
    } catch (err) {
      setError(err.message || 'Error al actualizar el perfil');
      console.error('Error details:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInstall = async () => {
    if (!deferredPrompt) return;
    
    try {
      await deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setDeferredPrompt(null);
      }
    } catch (err) {
      console.error('Error al instalar la app:', err);
    }
  };

  if (!userData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-red-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-700 to-red-900 dark:from-gray-900 dark:to-black p-4 transition-colors duration-300">
      <div className="max-w-2xl mx-auto space-y-6">
        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-xl">
            {error}
          </div>
        )}
        
        {success && (
          <div className="bg-green-500/10 text-green-500 p-4 rounded-xl">
            {success}
          </div>
        )}

        {/* Profile Section */}
        <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-6 shadow-xl">
          <div className="flex items-center space-x-4 mb-6">
            <div className="relative group">
              <div className="w-20 h-20 rounded-full overflow-hidden">
                <img 
                  src={userData.profile_photo_url || '/placeholder-avatar.png'} 
                  alt={userData.first_name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <label className="cursor-pointer">
                    <Upload className="w-6 h-6 text-white" />
                    <input 
                      type="file" 
                      className="hidden" 
                      accept="image/*"
                      onChange={handlePhotoUpload}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-white">
                {userData.first_name} {userData.last_name}
              </h1>
              <p className="text-red-200">Información de perfil</p>
            </div>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="ml-auto p-2 rounded-full hover:bg-white/10 transition-colors"
            >
              <Edit2 className="w-5 h-5 text-white" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <label className="text-red-200 text-sm">Nombre</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                {isEditing ? (
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white outline-none focus:border-red-500"
                  />
                ) : (
                  <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                    {userData.first_name}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Apellido</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                {isEditing ? (
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white outline-none focus:border-red-500"
                  />
                ) : (
                  <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                    {userData.last_name}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Correo electrónico</label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                {isEditing ? (
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white outline-none focus:border-red-500"
                  />
                ) : (
                  <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                    {userData.email}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-red-200 text-sm">Teléfono</label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-red-200 w-5 h-5" />
                {isEditing ? (
                  <input
                    type="tel"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white outline-none focus:border-red-500"
                  />
                ) : (
                  <div className="w-full bg-black/20 border border-white/10 rounded-xl px-12 py-3 text-white">
                    {userData.phone_number || 'No especificado'}
                  </div>
                )}
              </div>
            </div>

            {isEditing && (
              <div className="flex space-x-4 mt-6">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex-1 bg-red-500 hover:bg-red-600 text-white py-3 rounded-xl font-medium transition-colors disabled:opacity-50"
                >
                  {isLoading ? (
                    <Loader2 className="w-5 h-5 animate-spin mx-auto" />
                  ) : (
                    'Guardar cambios'
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="flex-1 bg-gray-600 hover:bg-gray-700 text-white py-3 rounded-xl font-medium transition-colors"
                >
                  Cancelar
                </button>
              </div>
            )}
          </form>
        </div>

        {/* Settings Options */}
        <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-6 shadow-xl space-y-4">
          <div 
            onClick={toggleDarkMode}
            className="flex items-center justify-between p-4 bg-black/20 rounded-xl cursor-pointer hover:bg-black/30 transition-colors"
          >
            <div className="flex items-center space-x-3">
              {darkMode ? (
                <Moon className="w-5 h-5 text-red-200" />
              ) : (
                <Sun className="w-5 h-5 text-red-200" />
              )}
              <span className="text-white">Modo oscuro</span>
            </div>
            <div className={`w-12 h-6 rounded-full p-1 transition-colors ${darkMode ? 'bg-red-500' : 'bg-gray-600'}`}>
              <div className={`w-4 h-4 rounded-full bg-white transition-transform ${darkMode ? 'translate-x-6' : 'translate-x-0'}`} />
            </div>
          </div>

          {deferredPrompt && (
            <button
              onClick={handleInstall}
              className="w-full flex items-center justify-center space-x-2 p-4 bg-red-500 hover:bg-red-600 rounded-xl text-white transition-colors"
            >
              <Download className="w-5 h-5" />
              <span>Instalar aplicación</span>
            </button>
          )}

          <button
            onClick={handleLogout}
            className="w-full flex items-center justify-center space-x-2 p-4 bg-red-500 hover:bg-red-600 rounded-xl text-white transition-colors"
          >
            <LogOut className="w-5 h-5" />
            <span>Cerrar sesión</span>
          </button>
        </div>

       

        {/* Version Information */}
        <div className="text-center text-white/60 text-sm">
          <p>Version 1.0.0</p>
          <p className="mt-1">© 2024 Asobares. Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  );
};

export default Settings;