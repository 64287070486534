import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { Search as SearchIcon, X, Loader2 } from 'lucide-react';
import BusinessCard from './BusinessCard';  // Ensure BusinessCard is updated as suggested

const FrasesDeBusqueda = [
  "¿Buscas un restaurante cerca?...",
  "Encuentra el mejor bar...",
  "Descubre lugares locales...",
  "¿Necesitas un lugar para comer?...",
  "Explora lugares cercanos...",
  "Encuentra el mejor ambiente...",
];

const Search = () => {
  const [query, setQuery] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [isInputHovered, setIsInputHovered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Fetch businesses
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://asobarespwa-d5839e56ffae.herokuapp.com/api/businesses/businesses/');
        setBusinesses(response.data);
        setFilteredBusinesses(response.data);
      } catch (err) {
        setError('Error al cargar los negocios. Por favor, intenta de nuevo más tarde.');
        console.error('Error fetching businesses:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinesses();
  }, []);

  // Animated placeholder effect
  useEffect(() => {
    const animatePlaceholder = async () => {
      if (isInputHovered || query) return;

      const currentCategory = FrasesDeBusqueda[currentCategoryIndex];
      
      if (isTyping) {
        for (let i = 0; i <= currentCategory.length; i++) {
          if (isInputHovered || query) break;
          setPlaceholderText(currentCategory.slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 50));
        }
        setIsTyping(false);
      } else {
        for (let i = currentCategory.length; i >= 0; i--) {
          if (isInputHovered || query) break;
          setPlaceholderText(currentCategory.slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 30));
        }
        setIsTyping(true);
        setCurrentCategoryIndex((prevIndex) => (prevIndex + 1) % FrasesDeBusqueda.length);
      }
    };

    animatePlaceholder();
  }, [isTyping, currentCategoryIndex, isInputHovered, query]);

  // Filter businesses based on search query and category
  useEffect(() => {
    const lowercaseQuery = query.toLowerCase();
    let filtered = businesses;

    // Filter by search query
    if (query) {
      filtered = filtered.filter(business => 
        business.establishment_name.toLowerCase().includes(lowercaseQuery) ||
        business.description.toLowerCase().includes(lowercaseQuery) ||
        business.category_name.toLowerCase().includes(lowercaseQuery)
      );
    }

    // Filter by category
    if (selectedCategory !== 'all') {
      filtered = filtered.filter(business => 
        business.category_name.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    setFilteredBusinesses(filtered);
  }, [query, businesses, selectedCategory]);

  // Get unique categories
  const categories = ['all', ...new Set(businesses.map(b => b.category_name.toLowerCase()))];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Header con logo y barra de búsqueda */}
      <div className="sticky top-0 z-10 bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg shadow-sm">
        <div className="container mx-auto">
          <div className="flex items-center h-16 px-4">
            <div className="flex items-center gap-4 w-full max-w-3xl mx-auto">
              <Link to="/" className="flex-shrink-0">
                <motion.img 
                  src="/logo512.png" 
                  alt="Logo de la aplicación" 
                  className="w-10 h-10 object-contain"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
              </Link>
              <div className="flex-1 relative">
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="relative"
                >
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={placeholderText}
                    onMouseEnter={() => setIsInputHovered(true)}
                    onMouseLeave={() => setIsInputHovered(false)}
                    className="w-full pl-12 pr-12 py-2 rounded-full border border-gray-200 dark:border-gray-700
                              bg-white/70 dark:bg-gray-800/70 backdrop-blur-md
                              focus:outline-none focus:ring-2 focus:ring-[#b22224]
                              placeholder-gray-400 dark:placeholder-gray-500 text-gray-900 dark:text-white
                              transition-all duration-300"
                  />
                  <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" size={20} />
                  {query && (
                    <button
                      onClick={() => setQuery('')}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 
                               hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
                    >
                      <X size={20} />
                    </button>
                  )}
                </motion.div>
              </div>
            </div>
          </div>

          {/* Categorías */}
          <div className="px-4 pb-4">
            <div className="flex gap-2 overflow-x-auto pb-2 max-w-3xl mx-auto">
              {categories.map((category) => (
                <motion.button
                  key={category}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-1 rounded-full text-sm font-medium whitespace-nowrap
                            transition-colors duration-300 ${
                    selectedCategory === category
                      ? 'bg-[#b22224] text-white'
                      : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  {category === 'all' ? 'Todos' : category}
                </motion.button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="container mx-auto px-4 py-8 pb-20">
        {loading ? (
          <div className="flex items-center justify-center py-20">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            >
              <Loader2 className="w-8 h-8 text-[#b22224]" />
            </motion.div>
          </div>
        ) : error ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center py-20"
          >
            <p className="text-red-500 dark:text-red-400">{error}</p>
          </motion.div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <AnimatePresence mode="wait">
              {filteredBusinesses.length > 0 ? (
                filteredBusinesses.map(business => (
                  <BusinessCard key={business.id} business={business} />
                ))
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="col-span-full text-center py-20"
                >
                  <p className="text-gray-500 dark:text-gray-400 text-lg">
                    No se encontraron negocios para tu búsqueda.
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Search;