import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const API_URL = 'https://asobarespwa-d5839e56ffae.herokuapp.com';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/users/login/`,
        { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          }
        }
      );

      if (response.data.access && response.data.refresh) {
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        
        navigate('/', { replace: true });
      } else {
        throw new Error('Respuesta del servidor no válida');
      }
    } catch (error) {
      let errorMessage = 'Error durante el inicio de sesión';
      
      if (error.response) {
        if (error.response.data.detail) {
          errorMessage = error.response.data.detail;
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        } else if (error.response.data.non_field_errors) {
          errorMessage = error.response.data.non_field_errors[0];
        }

        if (error.response.status === 401) {
          errorMessage = 'Usuario o contraseña incorrectos';
        }
      } else if (error.request) {
        errorMessage = 'No se pudo conectar con el servidor. Por favor, verifica tu conexión.';
      }
      
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 p-4">
      <div className="w-full max-w-md">
        {/* Logo Container */}
        <div className="flex justify-center mb-8">
          <img 
            src="/logoFindout.png" 
            alt="Findout Logo" 
            className="max-w-[200px] max-h-[100px] object-contain"
          />
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-8 py-6">
          <form onSubmit={handleSubmit}>
            <div className="coolinput mb-6">
              <label htmlFor="username" className="text">Usuario</label>
              <input
                type="text"
                id="username"
                className="input"
                placeholder="Ingresa tu usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>

            <div className="coolinput mb-6">
              <label htmlFor="password" className="text">Contraseña</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="input"
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  disabled={isLoading}
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm mt-2 mb-4">
                {error}
              </div>
            )}

            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember"
                  className="h-4 w-4 rounded border-gray-300"
                />
                <label htmlFor="remember" className="ml-2 text-sm text-gray-900 dark:text-gray-200">
                  Recordarme
                </label>
              </div>
              <a href="#" className="text-sm text-primary hover:text-primary-100 dark:text-primary">
                ¿Olvidaste tu contraseña?
              </a>
            </div>

            <button
              type="submit"
              className="w-full py-3 px-4 bg-primary hover:bg-red-700 text-white font-medium rounded-lg transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
            </button>

            <p className="mt-4 text-center text-sm text-gray-600 dark:text-gray-400">
              ¿No tienes una cuenta?{' '}
              <Link to="/help" className="text-primary hover:text-primary-100 dark:text-primary font-medium">
                Contactanos
              </Link>
            </p>
          </form>
        </div>

        <style jsx>{`
          .coolinput {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
          }

          .coolinput label.text {
            font-size: 0.75rem;
            color: #000000;
            font-weight: 700;
            position: relative;
            top: 0.5rem;
            margin: 0 0 0 7px;
            padding: 0 3px;
            background: #f3f4f6;
            width: fit-content;
            z-index: 1;
          }

          .dark .coolinput label.text {
            background: #111827;
            color: #ffffff;
          }

          .coolinput .input {
            padding: 11px 10px;
            font-size: 0.75rem;
            border: 2px #000000 solid;
            border-radius: 5px;
            background: #ffffff;
            width: 100%;
          }

          .dark .coolinput .input {
            background: #111827;
            border-color: #ffffff;
            color: white;
          }

          .coolinput .input:focus {
            outline: none;
            background: none;
          }

          .dark .coolinput .input::placeholder {
            color: #9ca3af;
            background: none;
          }
        `}</style>
      </div>
    </div>
  );
};

export default Login;