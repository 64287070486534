import React, { useState, useEffect } from 'react';
import { Download, X, Smartphone, Apple } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const PWAInstallPrompt = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // No mostrar en la ruta /installIOS
    if (location.pathname === '/installIOS') {
      setIsVisible(false);
      return;
    }

    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsIOS(isIOSDevice);

    const isInstalled = window.matchMedia('(display-mode: standalone)').matches;
    
    if (!isInstalled) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setIsVisible(true);
      });
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
    };
  }, [location]);

  const handleInstall = async () => {
    if (isIOS) {
      window.location.href = '/installIOS';
      return;
    }

    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setDeferredPrompt(null);
        setIsVisible(false);
      }
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-16 left-0 right-0 p-4 transform transition-transform duration-300 ease-in-out translate-y-0 z-50">
      <div className="mx-auto max-w-md bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg rounded-2xl p-6 shadow-xl border border-white/20 dark:border-gray-700/20">
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-4">
            <div className="bg-primary/10 dark:bg-primary/20 p-3 rounded-full">
              <Smartphone className="w-6 h-6 text-primary dark:text-primary-dark" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                ¡Instala nuestra App!
              </h3>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                Accede más rápido y disfruta de una mejor experiencia
              </p>
            </div>
          </div>
          <button
            onClick={() => setIsVisible(false)}
            className="text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <button
          onClick={handleInstall}
          className="w-full mt-6 bg-primary hover:bg-primary-dark text-white py-3 px-4 rounded-xl font-medium transition-colors flex items-center justify-center gap-2 dark:bg-primary-dark dark:hover:bg-primary"
        >
          {isIOS ? <Apple className="w-5 h-5" /> : <Download className="w-5 h-5" />}
          {isIOS ? 'Ver guía de instalación' : 'Instalar aplicación'}
        </button>
      </div>
    </div>
  );
};

export default PWAInstallPrompt;