import React, { useState, useEffect } from 'react';
import { 
  Clock, Phone, MapPin, Facebook, Instagram, Twitter, Globe, 
  Flame, Mail, MessageCircleMore, ChevronDown, ChevronUp,
  ExternalLink
} from 'lucide-react';
import { useParams } from 'react-router-dom';
import ImageGallery from './ImageGallery';

const BASE_URL = 'https://asobarespwa-d5839e56ffae.herokuapp.com/api';

// Enhanced Social Link Component
const SocialLink = ({ url, icon: Icon, platform }) => {
  if (!url) return null;
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-col items-center justify-center p-4 bg-white/10 rounded-xl hover:bg-red-500/20 transition-all duration-300 group"
      aria-label={platform}
    >
      <Icon className="w-6 h-6 text-gray-700 dark:text-gray-300 group-hover:text-red-600 dark:group-hover:text-red-400 transition-transform" />
      <span className="mt-2 text-sm text-gray-600 dark:text-gray-400">{platform}</span>
    </a>
  );
};

const WhatsAppIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className={className}
  >
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.149-.174.199-.297.298-.495.099-.198.05-.372-.025-.521-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.01-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.695.625.712.226 1.36.194 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.864 9.864 0 01-1.515-5.26c0-5.445 4.436-9.882 9.882-9.882 2.64 0 5.122 1.03 6.988 2.895a9.825 9.825 0 012.895 6.988c-.002 5.446-4.438 9.882-9.881 9.882m8.412-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L0 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413" 
    fillRule="evenodd" 
    clipRule="evenodd" 
    strokeLinejoin="round" 
    strokeMiterlimit="2"
  />
  </svg>
);

const FloatingWhatsAppButton = ({ phone }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isAnimated, setIsAnimated] = useState(true);
  
  useEffect(() => {
    // Initial animation
    const timer = setTimeout(() => {
      setIsAnimated(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!phone) return null;
  const formattedPhone = phone.replace(/\D/g, '');

  return (
    <div className="fixed right-6 bottom-24 z-50 flex items-center">
      <div className={`transition-all duration-300 ${isAnimated ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-8'}`}>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-3 mr-3 text-sm">
          Contáctanos por WhatsApp
        </div>
      </div>
      <a
        href={`https://wa.me/${formattedPhone}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`flex items-center justify-center p-4 bg-green-500 hover:bg-green-600 text-white rounded-full shadow-lg hover:shadow-green-500/25 transition-all duration-300 transform ${isAnimated ? 'animate-bounce' : 'hover:scale-105'}`}
        aria-label="Contact on WhatsApp"
      >
        <WhatsAppIcon className="w-7 h-7" />
      </a>
    </div>
  );
};

const WhatsAppProfile = ({ phone }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!phone) return null;
  const formattedPhone = phone.replace(/\D/g, '');

  return (
    <div 
      className="relative group cursor-pointer bg-green-500/10 rounded-lg p-3 hover:bg-green-500/20 transition-all duration-300"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center gap-3">
        <WhatsAppIcon className="w-6 h-6 text-green-500" />
        <span className="text-gray-700 dark:text-gray-300">WhatsApp</span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
      </div>
      {isExpanded && (
        <a
          href={`https://wa.me/${formattedPhone}`}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-full left-0 w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 mt-2 z-10 flex items-center gap-3"
        >
          <span className="text-gray-600 dark:text-gray-300">{phone}</span>
          <ExternalLink className="w-4 h-4" />
        </a>
      )}
    </div>
  );
};

// Enhanced Business Hours Component
const BusinessHours = ({ hours, businessId }) => {
  if (!hours?.length) return null;

  const businessHours = hours.filter(h => h.business === businessId);
  if (!businessHours.length) return null;

  const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const currentDay = new Date().getDay();
  
  const formatTime = (time) => {
    if (!time) return '';
    return new Date(`2000-01-01T${time}`).toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).toLowerCase();
  };

  const isCurrentlyOpen = (daySchedule) => {
    if (daySchedule.day !== currentDay || daySchedule.is_closed) return false;
    const now = new Date();
    const currentTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
    return currentTime >= daySchedule.opening_time && currentTime <= daySchedule.closing_time;
  };

  const currentDaySchedule = businessHours.find(h => h.day === currentDay);
  const isOpen = currentDaySchedule && isCurrentlyOpen(currentDaySchedule);

  return (
    <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-3">
          <Clock className="w-6 h-6 text-red-500 dark:text-red-400" />
          Horario de Atención
        </h3>
        <div className={`px-3 py-1 rounded-full text-sm font-medium ${
          isOpen ? 'bg-green-100 text-green-800 dark:bg-green-800/30 dark:text-green-400' : 
          'bg-red-100 text-red-800 dark:bg-red-800/30 dark:text-red-400'
        }`}>
          {isOpen ? 'Abierto' : 'Cerrado'}
        </div>
      </div>
      <div className="space-y-4">
        {businessHours
          .sort((a, b) => a.day - b.day)
          .map((daySchedule) => {
            if (daySchedule.is_closed) return null;
            const dayName = daysOfWeek[daySchedule.day];
            const isToday = daySchedule.day === currentDay;
            
            return (
              <div 
                key={daySchedule.day} 
                className={`flex justify-between items-center p-2 rounded-lg transition-colors ${
                  isToday ? 'bg-red-50 dark:bg-red-900/20' : ''
                }`}
              >
                <span className={`font-medium text-lg ${
                  isToday ? 'text-red-600 dark:text-red-400' : 'text-gray-600 dark:text-gray-300'
                }`}>
                  {dayName}
                </span>
                <span className="font-semibold text-gray-800 dark:text-gray-200 text-lg">
                  {`${formatTime(daySchedule.opening_time)} - ${formatTime(daySchedule.closing_time)}`}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

// Enhanced Address Component
const ExpandableAddress = ({ address }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shouldShowExpand = address?.length > 100;

  if (!address) return null;

  return (
    <div className="flex items-start gap-3 group">
      <MapPin className="w-6 h-6 text-red-500 dark:text-red-400 flex-shrink-0 mt-1" />
      <div className="flex-1">
        <p className={`text-gray-600 dark:text-gray-300 text-lg ${!isExpanded && shouldShowExpand ? 'line-clamp-2' : ''}`}>
          {address}
        </p>
        {shouldShowExpand && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300 text-sm font-medium flex items-center gap-1 mt-1"
          >
            {isExpanded ? (
              <>Ver menos <ChevronUp className="w-4 h-4" /></>
            ) : (
              <>Ver más <ChevronDown className="w-4 h-4" /></>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

// Main Component
const BusinessDetail = () => {
  const { id } = useParams();
  const [business, setBusiness] = useState(null);
  const [businessHours, setBusinessHours] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const businessResponse = await fetch(`${BASE_URL}/businesses/businesses/${id}/`);
        if (!businessResponse.ok) throw new Error('No se pudo obtener la información del negocio');
        const businessData = await businessResponse.json();

        const hoursResponse = await fetch(`${BASE_URL}/businesses/business-hours/?business=${id}`);
        const hoursData = await hoursResponse.json();

        const promotionsResponse = await fetch(`${BASE_URL}/businesses/promotions/?business=${id}`);
        const promotionsData = await promotionsResponse.json();

        setBusiness(businessData);
        setBusinessHours(hoursData);
        setPromotions(promotionsData.filter(promo => promo.business === parseInt(id)));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-pulse text-2xl text-gray-600 dark:text-gray-300">Cargando...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-2xl text-red-500 dark:text-red-400">Error: {error}</div>
      </div>
    );
  }

  if (!business) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="text-2xl text-gray-600 dark:text-gray-300">No se encontró el negocio</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pb-20">
      {/* Hero Section with improved mobile layout */}
      <div className="relative h-[50vh] md:h-[calc(100vh-8rem)]">
        <img
          src={business.cover_photo_url || 'https://via.placeholder.com/1500x500'}
          alt={business.establishment_name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/70 to-transparent">
          <div className="container mx-auto px-4 h-full flex items-end pb-6">
            <div className="flex items-center gap-4 w-full">
              <img
                src={business.profile_picture_url || 'https://via.placeholder.com/150'}
                alt={`${business.establishment_name} logo`}
                className="w-24 h-24 md:w-28 md:h-28 rounded-full border-4 border-white dark:border-gray-800 object-cover shadow-xl"
              />
              <div>
                <h1 className="text-3xl md:text-4xl font-bold text-white">{business.establishment_name}</h1>
                {business.category_name && (
                  <span className="inline-block bg-red-500/20 text-red-100 px-3 py-1 rounded-full text-sm mt-2">
                    {business.category_name}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-6 md:py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
          {/* Left Column */}
          <div className="space-y-6">
            {business.description && (
              <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">Sobre Nosotros</h2>
                <p className="text-gray-600 dark:text-gray-300 leading-relaxed text-lg">{business.description}</p>
              </div>
            )}

            {/* Contact Section */}
            <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg">
              <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">Contacto</h2>
              <div className="space-y-6">
                {business.phone && (
                  <div className="flex items-center gap-3">
                    <Phone className="w-6 h-6 text-red-500 dark:text-red-400 flex-shrink-0" />
                    <a href={`tel:${business.phone}`} className="text-gray-600 dark:text-gray-300 hover:text-red-500 dark:hover:text-red-400 transition-colors text-lg">
                      {business.phone}
                    </a>
                  </div>
                )}
                {business.email && (
                  <div className="flex items-center gap-3">
                    <Mail className="w-6 h-6 text-red-500 dark:text-red-400 flex-shrink-0" />
                    <a href={`mailto:${business.email}`} className="text-gray-600 dark:text-gray-300 hover:text-red-500 dark:hover:text-red-400 transition-colors text-lg break-all">
                      {business.email}
                    </a>
                  </div>
                )}
                <ExpandableAddress address={business.address} />
                {business.phone && <WhatsAppProfile phone={business.phone} />}
              </div>
            </div>

            {/* ImageGallery Section */}
            <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg">
              <ImageGallery businessId={parseInt(id)} />
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <BusinessHours hours={businessHours} businessId={parseInt(id)} />
            
            {/* Promotions Section */}
            {promotions.length > 0 && (
              <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">Promociones Activas</h2>
                <div className="space-y-4">
                  {promotions.map((promotion) => (
                    <div key={promotion.id} className="bg-white/10 dark:bg-gray-700/10 rounded-xl p-4 hover:shadow-xl transition-all duration-300 border border-gray-200 dark:border-gray-700">
                      <div className="flex items-start gap-4">
                        <div className="animate-bounce">
                          <Flame className="w-6 h-6 text-red-500 dark:text-red-400" />
                        </div>
                        <div className="flex-1">
                          <h3 className="text-xl font-bold text-gray-800 dark:text-gray-100 mb-2">{promotion.name}</h3>
                          {promotion.description && (
                            <p className="text-gray-600 dark:text-gray-300 mb-3 text-base">{promotion.description}</p>
                          )}
                          <div className="space-y-2">
                            {promotion.end_date && (
                              <p className="text-gray-500 dark:text-gray-400 text-sm">
                                Válido hasta: {new Date(promotion.end_date).toLocaleDateString()}
                              </p>
                            )}
                            {promotion.terms_conditions && (
                              <p className="text-gray-500 dark:text-gray-400 text-sm">{promotion.terms_conditions}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Official Links Section - Bento Box Style */}
            {(business.facebook_url || business.instagram_url || business.twitter_url || business.website_url) && (
              <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-md rounded-xl p-6 shadow-lg">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">Links Oficiales</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <SocialLink url={business.facebook_url} icon={Facebook} platform="Facebook" />
                  <SocialLink url={business.instagram_url} icon={Instagram} platform="Instagram" />
                  <SocialLink url={business.twitter_url} icon={Twitter} platform="Twitter" />
                  <SocialLink url={business.website_url} icon={Globe} platform="Sitio Web" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Floating WhatsApp Button */}
      <FloatingWhatsAppButton phone={business.phone} />
    </div>
  );
};

export default BusinessDetail;